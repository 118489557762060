<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-form-group class="col-12" :label="$t('capacity')">
                    <ValidationProvider
                        name="capacity"
                        v-slot="{ valid, errors }"
                    >
                        <b-input-group>
                            <b-form-input
                                v-model="form.quota"
                                :class="errors[0] ? 'is-invalid' : ''"
                            >
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                        ></b-form-invalid-feedback>
                    </ValidationProvider>
                </b-form-group>

            </b-row>
            <div class="col-12 mt-3 d-flex">
                <b-button @click="updateForm"
                          :disabled="formLoading"
                          type="button"
                          variant="primary"
                          class="btn-lg mr-2">
                    {{ $t('save').toUpperCase() }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>

import {ValidationProvider, ValidationObserver} from "vee-validate"
import QuotaManagementService from "@/services/QuotaManagementService";

export default {
    components: {
        ValidationProvider, ValidationObserver
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formLoading: false,
            form: {
               quota:null
            }
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    methods: {
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                QuotaManagementService.update(this.formId, this.form)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.$emit("updateFormSuccess")
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.formModalValidate)
                    })
            }
        }
    }
}
</script>
